import {
  Status as ReservationStatus,
  Type as ReservationType,
  SubStatus as ReservationSubStatus,
} from 'enums/reservation';

export interface IReservationColors {
  firstColor: string;
  secondColor: string;
}

export const getStatusColors = (
  status: ReservationStatus,
  isPastReservation: boolean,
): IReservationColors => {
  const colors = {
    [ReservationStatus.SEATED]: { firstColor: 'accent.green', secondColor: 'green1' },
    [ReservationStatus.PARTLY_SEATED]: { firstColor: 'accent.green', secondColor: 'green1' },
    [ReservationStatus.BLOCKED]: { firstColor: 'accent.red', secondColor: 'pigPink' },
    [ReservationStatus.CANCELLED]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [ReservationStatus.CONFIRMED]: isPastReservation
      ? { firstColor: 'accent.green', secondColor: 'green1' }
      : { firstColor: 'accent.blue', secondColor: 'blue1' },
    [ReservationStatus.NO_SHOW]: { firstColor: 'accent.red', secondColor: 'pigPink' },
    [ReservationStatus.BEING_LATE]: { firstColor: 'accent.blue', secondColor: 'blue1' },
    [ReservationStatus.PENDING]: { firstColor: 'accent.yellow', secondColor: 'yellow11' },
    [ReservationStatus.TEMPLATE]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [ReservationStatus.WALK_IN]: { firstColor: 'accent.violet', secondColor: 'violet1' },
    [ReservationStatus.WAITING_LIST]: { firstColor: 'accent.brown', secondColor: 'brown2' },
    [ReservationStatus.NONE]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [ReservationStatus.PRE_BOOKED]: { firstColor: 'accent.yellow', secondColor: 'yellow11' },
  };
  return colors[status] || { firstColor: 'accent.blue', secondColor: 'blue1' };
};

export const getTypeColor = (type: ReservationType) => {
  switch (type) {
    case ReservationType.BOOKING:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
    case ReservationType.WALK_IN:
      return { firstColor: 'accent.violet', secondColor: 'violet1' };
    case ReservationType.EVENT:
      return { firstColor: 'accent.yellow', secondColor: 'yellow11' };
    case ReservationType.BLOCKED:
      return { firstColor: 'accent.red', secondColor: 'pigPink' };
    case ReservationType.WAITING_LIST:
      return { firstColor: 'accent.brown', secondColor: 'brown2' };
    case ReservationType.TEMPLATE:
      return { firstColor: 'accent.gray', secondColor: 'gray16' };
    default:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
  }
};

export const getStatusColor = (
  status: ReservationStatus | ReservationSubStatus,
): IReservationColors => {
  switch (status) {
    case ReservationStatus.CONFIRMED:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
    case ReservationStatus.SEATED:
    case ReservationStatus.PARTLY_SEATED:
      return { firstColor: 'accent.green', secondColor: 'green1' };
    case ReservationStatus.PENDING:
    case ReservationStatus.PRE_BOOKED:
      return { firstColor: 'accent.yellow', secondColor: 'yellow11' };
    case ReservationStatus.NO_SHOW:
      return { firstColor: 'accent.red', secondColor: 'pigPink' };
    case ReservationStatus.CANCELLED:
      return { firstColor: 'accent.gray', secondColor: 'gray16' };
    default:
      return { firstColor: 'accent.blue', secondColor: 'blue1' };
  }
};

import { isTouchDevice } from 'utils/device/deviceHelper';
import { StripePaymentData } from 'types/reservation';
import { Status as ReservationStatus, SubStatus as ReservationSubStatus } from 'enums/reservation';

import { ILateCancellation } from 'types/app/reservations';
import { timestamp } from 'utils/date-time/timestamp';

export interface IStatusesEng {
  seated: string;
  blocked: string;
  cancelled: string;
  confirmed: string;
  no_show: string;
  pending: string;
  template: string;
  walk_in: string;
  waiting_list: string;
  pre_booked: string;
  none: string;
}

export const StatusesEng = {
  seated: 'Seated',
  partly_seated: 'Partly Seated',
  blocked: 'Blocked',
  cancelled: 'Canceled',
  confirmed: 'Confirmed',
  no_show: 'No Show',
  being_late: 'Being Late',
  pending: 'Pending',
  template: 'Template',
  walk_in: 'Walk-in',
  waiting_list: 'Waiting List',
  pre_booked: 'Pre-booked',
  none: 'None',
};

export const canReservationMove = () => {
  return !isTouchDevice();
};

export const getReservationTitle = (
  status: ReservationStatus | null | undefined,
  sub_status: ReservationSubStatus | null | undefined,
  title: string | undefined | null,
) => {
  switch (true) {
    case status === ReservationStatus.WALK_IN && sub_status !== ReservationSubStatus.WITH_NAME:
      return StatusesEng[ReservationStatus.WALK_IN];
    case status === ReservationStatus.BLOCKED && sub_status !== ReservationSubStatus.WITH_NAME:
      return StatusesEng[ReservationStatus.BLOCKED];
    default:
      return title;
  }
};

interface IPayForLateCancellation {
  now: number;
  bookingStatus: ReservationStatus;
  start_time: number;
  late_cancellation?: ILateCancellation;
  stripe_payment_data?: null | StripePaymentData;
}

export const shouldPayForLateCancellationNoShow = ({
  now,
  bookingStatus,
  start_time,
  late_cancellation,
  stripe_payment_data,
}: IPayForLateCancellation) => {
  const reservationStart = timestamp(start_time).toMilliseconds;

  const lateCancellationTime = late_cancellation?.time;

  const duration = timestamp(reservationStart).toSeconds - timestamp(now).toSeconds;

  const inTime = lateCancellationTime && duration < lateCancellationTime;

  const statuses = [ReservationStatus.NO_SHOW, ReservationStatus.CANCELLED];

  return (
    statuses.includes(bookingStatus) &&
    late_cancellation?.is_enabled &&
    !stripe_payment_data?.paymentSuccess &&
    inTime &&
    stripe_payment_data?.id !== ''
  );
};

import { Action } from 'types/index';
import { PaginatedResponse } from 'types/app/paginatedResponse';

export enum UsersActionTypes {
  Loaded = 'Users.Loaded',
}

export enum UserStatuses {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export enum UserRole {
  Admin = 'ADMIN',
  WebAdmin = 'WEB_ADMIN',
  WebDeveloper = 'WEB_DEVELOPER',
  Team = 'TEAM',
  Owner = 'OWNER',
  Manager = 'MANAGER',
  Staff = 'STAFF',
  ReadOnly = 'READ_ONLY',
  Marketing = 'MARKETING',
}

export enum Permission {
  // ACCOUNT_SETUP
  RestaurantInformation = 'restaurant_information',
  TimeLanguage = 'time_language',
  TableTimeManagement = 'table_time_management',
  Users = 'users',

  // TABLE_SETTINGS
  DiningRoomsAndAreas = 'dining_rooms_and_areas',
  TableGroup = 'table_groups',
  FloorPlanSetup = 'floor_plan_setup',

  // RESTAURANT_TIME_MANAGEMENT
  OpeningHours = 'opening_hours',
  CustomPeriods = 'custom_periods',

  // CALENDAR_SETTINGS
  CalendarReservationsSettings = 'calendar_reservations_settings',
  QuickSidebarSettings = 'sidebar_settings',

  // NOTIFICATION_SETTINGS
  EmailSettings = 'email_settings',
  SmsSettings = 'sms_settings',
  FeedbackSettings = 'feedback_settings',

  // WIDGET_SETTINGS
  WidgetSettings = 'widget_settings',
  WidgetFields = 'widget_fields',
  PolicesAndTerms = 'polices_and_terms',
  WidgetIntegration = 'widget_integrations',
  MarketingChannels = 'marketing_channels',

  // MARKETING_TOOLS
  Experiences = 'experiences',
  Events = 'events',
  SpecialOffers = 'special_offers',
  PaymentsAndDeposits = 'prepayments_and_deposits',
  Integrations = 'integrations',
  Banner = 'banners',

  // WEBSITE_BUILDER
  Database = 'database',
  RestaurantProfile = 'restaurant_profile',
  Gallery = 'gallery',
  MenuSettings = 'menu_settings',
  MenuEditor = 'menu_editor',

  // SUBSCRIPTION
  SubscriptionAndBilling = 'subscription_and_billing',
  Invoices = 'invoices',
}

export type IUser = {
  id: number;
  name: string;
  email: string;
  language?: string;
  status: UserStatuses;
  role: UserRole;
  permissions: Permission[];
  restaurants: number[];
  waiters: number[];
  tfa?: {
    status: boolean;
    channel: string;
    phone: string;
  };
};

export type UsersState = PaginatedResponse<IUser[]>;

export interface UsersAction extends Action<UsersState> {
  type: UsersActionTypes;
}

import { DateFormatKey } from 'types/dates';
import { timeFormat, dateFormatDate, DateTimeFormat } from 'utils/date-time/dates';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export const timestamp = (timestamp: number, timezone = 'UTC') => {
  return {
    get toMilliseconds() {
      if (timestamp.toString().length === 10) {
        return timestamp * 1000;
      }

      return timestamp;
    },

    get toSeconds() {
      if (timestamp.toString().length === 13) {
        return timestamp / 1000;
      }
      return timestamp;
    },

    get addDay() {
      return timestamp + 86400;
    },

    get startOfDayTimestamp() {
      return dayjs.unix(timestamp).tz(timezone).startOf('day').unix();
    },

    toTime: (dateFormat: keyof DateFormatKey) => {
      const format = timeFormat(dateFormat);

      return dayjs.unix(timestamp).tz(timezone).format(format).toString();
    },
    toDate: (dateFormat: keyof DateFormatKey) => {
      const format = dateFormatDate(dateFormat);

      return dayjs.unix(timestamp).tz(timezone).format(format).toString();
    },
    toDateTime: (dateFormat: keyof DateFormatKey) => {
      const format = DateTimeFormat(dateFormat);

      return dayjs.unix(timestamp).tz(timezone).format(format).toString();
    },
    ceilToInterval: (interval: number) => {
      const offset = timestamp.toString().length === 13 ? 1000 : 1;
      const diff = timestamp % (interval * offset);

      if (diff === 0) {
        return timestamp;
      }

      return timestamp + (interval * offset - diff);
    },
  };
};

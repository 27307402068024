import { Action } from 'types/index';

export enum ReservationSettingsActionTypes {
  Loaded = 'Reservation.Loaded',
}

export interface ReservationSettingsState {
  phone_required: boolean;
  default_booking_length: number;
  auto_arrival: boolean;
  slot_guests: boolean;
  event_modal: boolean;
  no_show: boolean;
  walk_in: boolean;
  canceled: boolean;
  big_spender_tag: boolean;
  black_list_tag: boolean;
  original_time: boolean;
  preselected_notifications: boolean;
  interval: number;
  show_stats: boolean;
  full_view_calendar: boolean;
  show_walk_in_list: boolean;
  show_phone_list: boolean;
}

export interface ReservationSettingsAction extends Action<ReservationSettingsState> {
  type: ReservationSettingsActionTypes;
}
